import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import styled from '@emotion/styled';
import { SpringGrid, layout, measureItems, makeResponsive } from 'react-stonecutter';
import carouselFormatters from '../../../utils/carouselFormatters';

import './style.css';

const GridSet = makeResponsive(measureItems(SpringGrid), {
  maxWidth: 1500,
  minPadding: 100
});

const Grid = styled(GridSet)`
  list-style: none;
  padding: 0;
  margin: 0 auto;
`;

const Image = styled(Img)`
  border-radius: 5px;
  transition: filter 0.3s;
  :hover {
    filter: brightness(87.5%);
  }
`;

const Container = styled.div`
  height: 100%;
`;

const modalStyles = {
  blanket: base => ({
    ...base,
    zIndex: 900
  }),
  positioner: base => ({ ...base, zIndex: 901 })
};

const Gallery = ({ images, tagFilter }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0);

  const closeModal = () => setModalIsOpen(false);
  const openModal = imageIndex => {
    setModalCurrentIndex(imageIndex);
    setModalIsOpen(true);
  };

  const filterImg = images.filter(el => {
    if (tagFilter === 'alles') return el;
    if (el.tag === tagFilter) return el;
    return null;
  });

  return (
    <section className="section">
      <div className="container">
        <Container>
          <Grid
            className="grid"
            columnWidth={300}
            gutterWidth={20}
            gutterHeight={20}
            layout={layout.pinterest}
            springConfig={{ stiffness: 170, damping: 26 }}
          >
            {filterImg.map((image, i) => (
              <div key={image.id} className="grid-item">
                <a
                  href={image.originalImg}
                  onClick={e => {
                    e.preventDefault();
                    openModal(i);
                  }}
                >
                  <Image fluid={image} title={image.caption} width="100%" />
                </a>
              </div>
            ))}
          </Grid>

          {ModalGateway && (
            <ModalGateway>
              {modalIsOpen && (
                <Modal onClose={closeModal} styles={modalStyles}>
                  <Carousel
                    views={filterImg.map(({ originalImg, caption }) => ({
                      source: originalImg,
                      caption
                    }))}
                    currentIndex={modalCurrentIndex}
                    formatters={carouselFormatters}
                    components={{ FooterCount: () => null }}
                    frameProps={{ autoSize: 'height' }}
                  />
                </Modal>
              )}
            </ModalGateway>
          )}
        </Container>
      </div>
    </section>
  );
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  tagFilter: PropTypes.string.isRequired
};

export default Gallery;
