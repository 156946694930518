const carouselFormatters = {
  getAltText: ({ data: { caption }, index }) =>
    caption || `${index + 1}. picture`,

  getNextLabel: ({ currentIndex, views }) =>
    `Show a(z) ${currentIndex + 2}. picture a(z) ${views.length} of`,
  getPrevLabel: ({ currentIndex, views }) =>
    `Show a(z) ${currentIndex}. picture a(z) ${views.length} of`,

  getNextTitle: () => 'Following (right arrow)',
  getPrevTitle: () => 'Previous (left arrow)',

  getCloseLabel: () => 'Closure (esc)',
  getFullscreenLabel: ({ isFullscreen }) =>
    isFullscreen ? 'Exit full screen view (f)' : 'Switch to full screen (f)'
};

export default carouselFormatters;
