/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Gallery from './Gallery';

const inputStyle = css`
  visibility: hidden;
  height: 0;
  width: 0;
  opacity: 0;
`;

const Label = styled.label`
  font-size: 0.7rem;
  ${p =>
    p.theme.minWidth.tablet(css`
      font-size: 1rem;
    `)}
`;

const GalleryFilter = ({ images }) => {
  const [serviceState, setServiceState] = useState({
    types: ['Alles', 'Trockenbau', 'Malerarbeiten', 'Fassade', 'Renovierung'],
    pick: 'Alles'
  });

  const handleRadio = e => {
    setServiceState({
      ...serviceState,
      pick: e.target.value
    });
  };

  return (
    <>
      <div className="buttons has-addons is-centered">
        {serviceState.types.map(type => {
          const isCurrent = serviceState.pick === type;
          return (
            <Label
              key={type}
              className={isCurrent ? 'button is-success is-selected' : 'button'}
              htmlFor={type}
            >
              <input
                css={inputStyle}
                type="radio"
                name="serviceTypes"
                id={type}
                value={type}
                onChange={handleRadio}
              />
              {type}
            </Label>
          );
        })}
      </div>
      <Gallery tagFilter={serviceState.pick.toLowerCase()} images={images} />
    </>
  );
};

GalleryFilter.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default GalleryFilter;
