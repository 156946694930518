import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Layout, SEO } from '../components/common';
import website from '../../config/website';
import GalleryFilter from '../components/gallery/GalleryFilter';

const Title = styled.div`
  padding-top: 3em;
`;

export const GalleryPageTemplate = ({ title, images }) => (
  <>
    <Title>
      <div className="container">
        <div className="section has-text-centered">
          <h3 className="is-size-2">{title}</h3>
        </div>
      </div>
    </Title>
    <GalleryFilter images={images} />
  </>
);

GalleryPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired
};

const GalleryPage = ({
  data: {
    markdownRemark: { frontmatter }
  },
  location
}) => (
  <Layout customSEO>
    <SEO
      title={`${frontmatter.title} | ${website.siteTitle}`}
      pathname={location.pathname}
    />
    <GalleryPageTemplate
      title={frontmatter.title}
      images={frontmatter.gallery.map(el => ({
        id: el.image.id,
        tag: el.tag,
        ...el.image.childImageSharp.fluid,
        caption: `${el.tag} – ${el.title}`
      }))}
    />
  </Layout>
);

GalleryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }).isRequired
  }).isRequired
};

export default GalleryPage;

export const galleryPageQuery = graphql`
  query GalleryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        gallery {
          title
          tag
          image {
            id
            childImageSharp {
              fluid(maxWidth: 1366, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
                originalImg
              }
            }
          }
        }
      }
    }
  }
`;
